import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Overcast 1.0",
  "tags": "Apps",
  "date": "2014-07-16T00:00:00.000Z",
  "link": "https://overcast.fm",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Marco Arment `}<a parentName="p" {...{
        "href": "http://www.marco.org/2014/07/16/overcast"
      }}>{`just shipped 1.0 of his new podcast app`}</a>{` — `}<a parentName="p" {...{
        "href": "https://overcast.fm"
      }}>{`Overcast`}</a>{`. As expected it’s pretty good. Of course I have a few complaints: I wish the main list showed episodes, not podcasts (you can create a playlist to do that, but it’s one level deeper), the cover to shownotes transition is a bit awkward and the playback controls are comically large. That’s about it. There’s also a lot of nice touches, including two great features that I’ve never heard of before: Smart Speed and Voice Boost. Smart Speed shortens silences by automatically adjusting the playback speed and Voice Boost enhances the voice by tweaking the EQ. Both of these features work amazingly well in practice and make for a better listening experience. I’m sure these features are going to get ripped off like crazy by other apps.`}</p>
    <p>{`Having played with it for only a few hours, I can already tell it’s one of the best podcast app on iOS. And it’s only 1.0. I’m super excited about it and can’t wait to see what future updates will bring. Marco is still on top of his game.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      